import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import CanvasGrid from "../components/canvas-grid"
import AppTicker from "../components/appticker"
import ImgWall from "../components/imgwall"

const GalleryPage = ({ data }) => {
  const { t } = useTranslation("gallery")

  return (
    <Layout>
      <SEO
        title={t("metadata.title")}
        description={t("metadata.desc")}
        image="gallery.jpg"
      />

      <CanvasGrid class="canvas-grid--light" theme="light" />

      <div className="container-fluid color--black page-intro">
        <div className="row">
          <div className="content text--sm offset-lg-2 col-lg-8 col-xl-6">
            <h1 className="title text--sm">{t("hero.title")}</h1>
            <p>{t("hero.content")}</p>
          </div>
        </div>
      </div>

      <ImgWall
        galleryImages={data.galleryImages}
        images={t("gallery", { returnObjects: true })}
      />

      <AppTicker theme="light" />
    </Layout>
  )
}

export const query = graphql`
  query GallerQuery {
    galleryImages: allFile(
      filter: {
        relativePath: { regex: "/gallery/" }
        ext: { regex: "/(jpg|png)/" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 600
            sizes: "(min-width: 1200px) 600px, (min-width: 768px) 300px, 100vw"
          ) {
            ...GatsbyImageSharpFluid
            originalName
            presentationWidth
          }
        }
      }
    }
  }
`

export default GalleryPage
